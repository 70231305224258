import { makeObservable, observable, runInAction } from 'mobx'
import { notification } from 'antd'
import type { StoresStore, AppStore, UserStore, WidgetsStore, BreadcrumbStore } from './types'
import { createUserStore } from './user-store'
import { createStoresStore } from './stores-store'
import { createWidgetsStore } from './widgets-store'
import { createBreadcrumbStore } from './breadcrumb-store'

class AppStoreImpl implements AppStore {
  loading: boolean = false
  user: UserStore
  stores: StoresStore
  widgets: WidgetsStore
  breadcrumb: BreadcrumbStore

  constructor () {
    makeObservable<AppStore>(this, {
      loading: observable
    })
    this.user = createUserStore(this)
    this.stores = createStoresStore(this)
    this.widgets = createWidgetsStore(this)
    this.breadcrumb = createBreadcrumbStore(this)
  }

  tryToFetch = async <T = any>(loader: Promise<Response>): Promise<null | T> => {
    let result = null
    runInAction(() => { this.loading = true })

    try {
      const response = await loader
      result = await response.json()

      if (response.status !== 200 && response.status !== 201 && 'message' in result) {
        notification.error({
          message: result.message
        })
      }
      if (response.status === 401) {
        runInAction(() => {
          this.user.user = null
        })
      }

      if (response.status !== 200 && response.status !== 201) {
        result = null
      }
    } catch (err) {
      console.error('Loading error:', err)
      notification.error({
        message: 'Internal error'
      })
    }

    runInAction(() => { this.loading = false })

    return result
  }
}

export function createAppStore (): AppStore {
  return new AppStoreImpl()
}
