import React from 'react'
import { type BreadcrumbItemProps } from 'antd'
import { makeObservable, observable, action } from 'mobx'
import type { BreadcrumbStore, AppStore, BreadcrumbItem } from './types'

class BreadcrumbStoreImpl implements BreadcrumbStore {
  breadcrumbItems: BreadcrumbItemProps[] = []

  constructor (private readonly root: AppStore) {
    makeObservable<BreadcrumbStore>(this, {
      breadcrumbItems: observable,
      setBreadcrumbItems: action.bound
    })
  }

  setBreadcrumbItems = (items: BreadcrumbItem[]): void => {
    this.breadcrumbItems = items.map(v => ({
      href: v.link,
      title: (<>{v.icon}<span>{v.title}</span></>)
    }))
  }
}

export function createBreadcrumbStore (root: AppStore): BreadcrumbStore {
  return new BreadcrumbStoreImpl(root)
}
