import React, { createContext, useContext, type FC, type ReactNode } from 'react'
import { createAppStore } from './app-store'
import type { AppStore } from './types'

const AppStoreContext = createContext<AppStore | null>(null)
const StoreProvider = AppStoreContext.Provider

export function useAppStore (): AppStore {
  const store = useContext(AppStoreContext)

  if (store === null) {
    throw new Error('Store is not initialed')
  }

  return store
}

export interface AppStoreProviderProps {
  children: ReactNode
}

export const AppStoreProvider: FC<AppStoreProviderProps> = ({ children }) => (
  <StoreProvider value={createAppStore()}>
    {children}
  </StoreProvider>
)
