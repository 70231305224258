import React, { type FC, useEffect, useMemo } from 'react'
import { Card, Space, Button, Statistic, Row, Col, Spin } from 'antd'
import {
  ReloadOutlined, CheckCircleOutlined, CloseCircleOutlined
} from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '../store'

export const Widgets: FC = observer(() => {
  const root = useAppStore()
  const { widgets } = root

  useEffect(() => {
    void widgets.countActiveStores()
  }, [widgets])

  const reloadButton = useMemo(() => (
    <Button type="text" icon={<ReloadOutlined />} onClick={() => { void widgets.countActiveStores() }} />
  ), [widgets])

  return (
    <Spin spinning={root.loading}>
      <Space>
        <Card
          title="Active stores"
          extra={reloadButton}
          style={{ minWidth: 250 }}
        >
          <Row>
            <Col span={12}>
              <Statistic
                title="Active"
                value={widgets.activeStores}
                valueStyle={{ color: '#3f8600' }}
                prefix={<CheckCircleOutlined />}
                        />
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Statistic
                title="Total"
                value={widgets.totalStores}
                        />
            </Col>
          </Row>
        </Card>
        <Card title="Inactive stores"
          extra={reloadButton}
        >
          <Statistic
            title="Inactive"
            value={widgets.inactiveStores}
            valueStyle={{ color: '#ff0000' }}
            prefix={<CloseCircleOutlined />}
                />
        </Card>
      </Space>
    </Spin>
  )
})
