import React, { type FC, useState, useCallback, useEffect } from 'react'
import { Form, Input, Button, Typography, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '../store'
import { isNil } from 'lodash'

const { Title } = Typography
const { Item } = Form

export const UserForm: FC = observer(() => {
  const [form] = Form.useForm()
  const [editMode, setEditMode] = useState(false)
  const root = useAppStore()
  const { user } = root
  const onSave = useCallback(({ name, email }: { name: string, email: string }) => {
    if (user.user === null) {
      return null
    }
    void user.updateUser({
      id: user.user?.id,
      name,
      email
    }).then(_ => {
      setEditMode(false)
    })
  }, [user, setEditMode])

  useEffect(() => {
    form.setFieldsValue(user.user)
  }, [form, user.user])

  return (
    <Spin spinning={root.loading}>
      <Title level={2}>
        Profile {!editMode ? <Button style={{ verticalAlign: 'middle' }} icon={<EditOutlined />} onClick={() => { setEditMode(true) }} /> : null }
      </Title>
      <Form
        form={form}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={onSave}
            >
        <Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input name!' }]}
                >
          {editMode ? <Input /> : user.user?.name}
        </Item>
        <Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input email!' },
            ({ getFieldValue }) => ({
              async validator (_, value: string) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                if (!isNil(value) && !emailRegex.test(value)) {
                  return await Promise.reject(new Error('Please enter a valid email address'))
                }
                return await Promise.resolve()
              }
            })
          ]}
      >
          {editMode ? <Input /> : user.user?.email}
        </Item>

        <Item
          label="Login"
          name="login"
        >
          {user.user?.login}
        </Item>

        <Item
          label="Role"
          name="role"
          >
          {user.user?.role}
        </Item>

        {editMode
          ? (
            <Item>
              <Button style={{ marginRight: 30 }} onClick={() => { setEditMode(false) }} >Cancel</Button>

              <Button type="primary" htmlType="submit">
                {editMode ? 'Save' : 'Add'}
              </Button>
            </Item>
            )
          : null }
      </Form>
    </Spin>
  )
})
