import React, { type FC, type ReactNode, useEffect, useState } from 'react'
import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppStore } from '../store'

interface RequireAuthProps {
  children?: ReactNode
}

export const RequireAuth: FC<RequireAuthProps> = observer(({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useAppStore()
  const location = useLocation()

  useEffect(() => {
    if (user.user === null) {
      void user.tryToLoadUser().finally(() => {
        setIsLoading(false)
      })
    } else {
      setIsLoading(true)
    }
  }, [user, user.user, setIsLoading])

  if (isLoading && user.user === null) {
    return <Spin spinning={isLoading} />
  }

  if (user.user === null) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
})
