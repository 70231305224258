import React, { type FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { DashboardOutlined, UserOutlined } from '@ant-design/icons'
import { PageLayout, UserForm } from '../components'
import { useAppStore } from '../store'

export const ProfilePage: FC = observer(() => {
  const { breadcrumb } = useAppStore()

  useEffect(() => {
    breadcrumb.setBreadcrumbItems([
      {
        title: 'Dashboard',
        link: '/',
        icon: <DashboardOutlined />
      },
      {
        title: 'Profile',
        link: '/profile',
        icon: <UserOutlined />
      }
    ])
  }, [breadcrumb])

  return (
    <PageLayout>
      <UserForm />
    </PageLayout>
  )
})
