import React, { type ReactNode, type FC, useState, useMemo, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  DashboardOutlined,
  AppstoreAddOutlined,
  LogoutOutlined
} from '@ant-design/icons'
import { Layout, Menu, Button, theme, Typography, Breadcrumb } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useAppStore } from '../store'
const { Title } = Typography

const { Header, Sider, Content } = Layout

interface PageLayoutProps {
  children?: ReactNode
}

export const PageLayout: FC<PageLayoutProps> = observer(({ children }) => {
  const { user, breadcrumb } = useAppStore()
  const [collapsed, setCollapsed] = useState(false)
  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken()
  const navigate = useNavigate()

  const logoutHandler = useCallback(() => {
    user.logout()
    navigate('/login')
  }, [
    user,
    navigate
  ])

  const profileHandler = useCallback(() => { navigate('/profile', { replace: true }) }, [
    navigate
  ])

  const items = useMemo(() => (
    [
      {
        key: 'dashboard',
        icon: <DashboardOutlined />,
        label: 'Dashboard',
        onClick: () => { navigate('/', { replace: true }) }
      },
      {
        key: 'stores',
        icon: <AppstoreAddOutlined />,
        label: 'Stores',
        onClick: () => { navigate('/stores', { replace: true }) }
      },
      {
        key: 'profile',
        icon: <UserOutlined />,
        label: 'Profile',
        onClick: profileHandler
      }
    ]
  ), [navigate, profileHandler])

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Title level={4} style={{ color: 'white', textAlign: 'center' }}>{collapsed ? 'SC' : 'Store Controller'}</Title>
        <Menu
          theme="dark"
          mode="inline"
          selectable={false}
          items={items}
                />
      </Sider>
      <Layout style={{ height: '100vh' }}>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => { setCollapsed(!collapsed) }}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64
            }}
                    />

          <div style={{ float: 'right' }}>
            <Button
              type="text"
              icon={<UserOutlined />}
              onClick={profileHandler}
              style={{
                height: 64,
                marginRight: 20
              }}>
              {user.user?.name}
            </Button>
            <Button
              type="text"
              icon={<LogoutOutlined />}
              onClick={logoutHandler}
              style={{
                height: 64
              }}>
              Logout
            </Button>
          </div>
        </Header>
        <Breadcrumb
          style={{ margin: 20 }}
          items={breadcrumb.breadcrumbItems as any}
        />
        <Content
          style={{
            overflow: 'auto',
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG
          }}
                >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
})
