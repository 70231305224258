import { serverUrl } from './config'

export async function login (values: { login: string, password: string }): Promise<Response> {
  return await fetch(`${serverUrl}/api/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  })
}
