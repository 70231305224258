import { serverUrl } from './config'
import type { User } from '../store/types'

export async function getUser (userId: number, token: string): Promise<Response> {
  return await fetch(`${serverUrl}/api/users/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}

export async function updateUser (user: Partial<User> & { id: number }, token: string): Promise<Response> {
  return await fetch(`${serverUrl}/api/users/${user.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      id: user.id,
      name: user?.name,
      email: user?.email
    })
  })
}
