import React, { type FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { DashboardOutlined, AppstoreAddOutlined } from '@ant-design/icons'
import { PageLayout, StoresTable } from '../components'
import { useAppStore } from '../store'

export const StoresPage: FC = observer(() => {
  const { breadcrumb } = useAppStore()

  useEffect(() => {
    breadcrumb.setBreadcrumbItems([
      {
        title: 'Dashboard',
        link: '/',
        icon: <DashboardOutlined />
      },
      {
        title: 'Stores',
        link: '/stores',
        icon: <AppstoreAddOutlined />
      }
    ])
  }, [breadcrumb])

  return (
    <PageLayout>
      <StoresTable />
    </PageLayout>
  )
})
