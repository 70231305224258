import React, { type FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { DashboardOutlined } from '@ant-design/icons'
import { PageLayout, Widgets } from '../components'
import { useAppStore } from '../store'

export const DashboardPage: FC = observer(() => {
  const { breadcrumb } = useAppStore()

  useEffect(() => {
    breadcrumb.setBreadcrumbItems([{
      title: 'Dashboard',
      link: '/',
      icon: <DashboardOutlined />
    }])
  }, [breadcrumb])

  return (
    <PageLayout>
      <Widgets />
    </PageLayout>
  )
})
