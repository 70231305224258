import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Input, Button, Typography, Spin, notification, Divider, Checkbox } from 'antd'
import { isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useAppStore, type Store } from '../store'

const { Title } = Typography
const { Item } = Form

interface StoreFormProps {
  storeId?: string
}

export const StoreForm: FC<StoreFormProps> = observer(({ storeId }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [store, setStore] = useState<Store | null>(null)
  const root = useAppStore()
  const { stores } = root
  const onSave = useCallback((values: { name: string, subdomain: string }) => {
    void stores.saveStore({
      ...store,
      ...values
    }).then(_ => {
      navigate('/stores')
      notification.success({
        message: 'Store successfully saved'
      })
    }).catch(reason => console.error(reason))
  }, [stores, store, navigate])

  useEffect(() => {
    if (!isNil(storeId)) {
      void stores.getStore(storeId).then(value => {
        setStore(value)
      })
    }
  }, [storeId, stores, setStore])

  const initialValues = useMemo(() => ({
    ...store,
    createdBy: store?.user?.name,
    dateCreated: !isNil(store) ? new Date(store.createdAt).toLocaleString() : undefined,
    lastEdited: !isNil(store) ? new Date(store.updatedAt).toLocaleString() : undefined
  }), [store])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <Spin spinning={root.loading}>
      <Title level={2}>{!isNil(storeId) ? `Edit store: ${store?.name}` : 'New store'}</Title>
      <Form
        form={form}
        wrapperCol={{ span: 14 }}
        layout="vertical"
        onFinish={onSave}
        requiredMark={true}
        initialValues={{ withProducts: false }}
      >
        <Item name="withProducts" valuePropName="checked">
          <Checkbox defaultChecked={true} value={Form.useWatch('withProducts', form)}>With products</Checkbox>
        </Item>
        <Divider orientation="left">Store:</Divider>
        <Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input store name!' }]}
            >
          <Input />
        </Item>
        <Item
          label="Subdomain"
          name="subdomain"
          rules={[
            { required: true, message: 'Please input store subdomain!' },
            ({ getFieldValue }) => ({
              async validator (_, value: string) {
                const subdomainRegex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)$/
                if (!isNil(value) && !subdomainRegex.test(value)) {
                  return await Promise.reject(new Error('Please enter a valid subdomain name'))
                }
                return await Promise.resolve()
              }
            })
          ]}
            >
          <Input />
        </Item>
        <Divider orientation="left">Stripe:</Divider>
        <Item
          label="Public key"
          name="stripePubKey"
          rules={[{ required: true, message: 'Please input Stripe Public key!' }]}
          >
          <Input />
        </Item>
        <Item
          label="API key"
          name="stripeApiKey"
          rules={[{ required: true, message: 'Please input Stripe API key!' }]}
          >
          <Input />
        </Item>
        <Divider orientation="left">Shipstation:</Divider>
        <Item
          label="Store ID"
          name="shipstationStoreId"
          // rules={[{ required: true, message: 'Please input Shipstation Store ID!' }]}
          >
          <Input />
        </Item>
        <Item
          label="API key"
          name="shipstationApiKey"
          initialValue={'c33293807c774e0a9d66177944f3a159'}
          >
          <Input />
        </Item>
        <Item
          label="API secret"
          name="shipstationSercet"
          initialValue={'b0ae8b13e652492fbf6b01c473da2c2e'}
          >
          <Input />
        </Item>
        {/* { !isNil(storeId)
          ? (
            <>
              <Divider orientation="left">User:</Divider>
              <Item
                label="Email"
                name="userEmail"
          >
                <Input readOnly />
              </Item>
              <Item
                label="Password"
                name="userPassword"
          >
                <Input.Password readOnly />
              </Item>
              <Item
                label="Active"
                name="active"
                valuePropName="checked"
            >
                <Checkbox disabled />
              </Item>
              <Item
                label="Created By"
                name="createdBy"
            >
                <Input readOnly disabled />
              </Item>
              <Item
                label="Date Created"
                name="dateCreated"
            >
                <Input readOnly disabled />
              </Item>
              <Item
                label="Last Edited"
                name="lastEdited"
            >
                <Input readOnly disabled />
              </Item>
            </>
            )
          : null} */}
        <Item>
          <Button style={{ marginRight: 30 }} onClick={() => { navigate('/stores') }} >Cancel</Button>

          <Button type="primary" htmlType="submit">
            {!isNil(storeId) ? 'Save' : 'Add'}
          </Button>
        </Item>
      </Form>
    </Spin>
  )
})
