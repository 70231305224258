import React, { type FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Input, Button, Spin } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import { useAppStore } from '../store'
import { useNavigate } from 'react-router-dom'

export const LoginPage: FC = observer(() => {
  const root = useAppStore()
  const { user } = root
  const navigate = useNavigate()

  const onFinish = useCallback((values: { login: string, password: string }) => {
    void user.login(values).then(() => { navigate('/') }).catch((reason) => console.error(reason))
  }, [user, navigate])

  return (
    <Spin spinning={root.loading}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
            >
          <Form.Item
            name="login"
            rules={[{ required: true, message: 'Please input your Login!' }]}
                >
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
                >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
                    />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  )
})
