import React, { type FC } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { RequireAuth } from './components'
import { ProfilePage, DashboardPage, StoresPage, LoginPage, StorePage } from './pages'
import './styles.css'

export const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <DashboardPage />
            </RequireAuth>
          }
        />
        <Route
          path="/stores"
          element={
            <RequireAuth>
              <StoresPage />
            </RequireAuth>
              }
          />
        <Route
          path="/stores/new"
          element={
            <RequireAuth>
              <StorePage />
            </RequireAuth>
              }
          />
        <Route
          path="/stores/edit/:storeId"
          element={
            <RequireAuth>
              <StorePage />
            </RequireAuth>
              }
          />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}
