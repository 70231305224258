import { makeObservable, observable, computed, runInAction } from 'mobx'
import { countActiveStores } from '../api'
import type { WidgetsStore, AppStore } from './types'

class WidgetsStoreImpl implements WidgetsStore {
  activeStores: number = 0
  totalStores: number = 0

  constructor (private readonly root: AppStore) {
    makeObservable<WidgetsStore>(this, {
      activeStores: observable,
      inactiveStores: computed,
      totalStores: observable
    })
  }

  get inactiveStores (): number {
    return this.totalStores - this.activeStores
  }

  countActiveStores = async (): Promise<void> => {
    const stat = await this.root.tryToFetch<{ activeCount: number, total: number }>(countActiveStores(this.root.user.token ?? ''))

    if (stat !== null) {
      const { activeCount, total } = stat
      runInAction(() => {
        this.activeStores = activeCount
        this.totalStores = total
      })
    }
  }
}

export function createWidgetsStore (root: AppStore): WidgetsStore {
  return new WidgetsStoreImpl(root)
}
