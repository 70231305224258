import { omitBy, isUndefined } from 'lodash'
import { serverUrl } from './config'
import type { PaginationFilter, Store } from '../store/types'

export async function getStores (filter: PaginationFilter, token: string): Promise<Response> {
  return await fetch(`${serverUrl}/api/stores?${new URLSearchParams(filter as Record<string, string>).toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}

export async function getStore (storeId: number, token: string): Promise<Response> {
  return await fetch(`${serverUrl}/api/stores/${storeId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}

export async function saveStore (store: Partial<Store>, token: string): Promise<Response> {
  const savedEntity = omitBy({
    id: store?.id,
    name: store?.name,
    subdomain: store?.subdomain,
    stripePubKey: store?.stripePubKey,
    stripeApiKey: store?.stripeApiKey,
    shipstationApiKey: store?.shipstationApiKey,
    shipstationSercet: store?.shipstationSercet,
    shipstationStoreId: store?.shipstationStoreId,
    withProducts: store?.withProducts,
    meilisearchKey: store?.meilisearchKey,
    userEmail: store?.userEmail,
    userPassword: store?.userPassword,
    active: store?.active
  }, isUndefined)

  return await fetch(
    !isUndefined(savedEntity.id) ? `${serverUrl}/api/stores/${savedEntity.id}` : `${serverUrl}/api/stores`,
    {
      method: !isUndefined(savedEntity.id) ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(savedEntity)
    })
}

export async function deleteStore (storeId: number, token: string): Promise<Response> {
  return await fetch(`${serverUrl}/api/stores/${storeId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}

export async function countActiveStores (token: string): Promise<Response> {
  return await fetch(`${serverUrl}/api/stores/count-active`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}

export async function activateStore (storeId: number, token: string): Promise<Response> {
  return await fetch(`${serverUrl}/api/stores/${storeId}/activate`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}

export async function deactivateStore (storeId: number, token: string): Promise<Response> {
  return await fetch(`${serverUrl}/api/stores/${storeId}/deactivate`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
}

export async function getStatuses (dockerServiceIds: string[], token: string): Promise<Response> {
  return await fetch(
    `${serverUrl}/api/stores/statuses`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        dockerServiceIds
      })
    })
}
