import { makeObservable, observable, runInAction } from 'mobx'
import { notification } from 'antd'
import { login, getUser, updateUser } from '../api'
import type { User, UserStore, AppStore } from './types'

const USER_TOKEN_KEY = 'user_token_key'
const USER_ID_KEY = 'user_id_key'

class UserStoreImpl implements UserStore {
  user: User | null = null
  token: string | null = null

  constructor (private readonly root: AppStore) {
    makeObservable<UserStore, 'token'>(this, {
      user: observable,
      token: observable
    })
  }

  login = async (values: { login: string, password: string }): Promise<void> => {
    const loginData = await this.root.tryToFetch<{ userId: number, token: string }>(login(values))

    if (loginData !== null) {
      const { userId, token } = loginData as { userId: number, token: string }
      const user = await this.root.tryToFetch<User>(getUser(userId, token))

      if (user !== null) {
        runInAction(() => {
          this.user = user
          this.token = token
          localStorage.setItem(USER_TOKEN_KEY, token)
          localStorage.setItem(USER_ID_KEY, userId.toString())
        })
      }
    } else {
      throw new Error('Login failed')
    }
  }

  logout = (): void => {
    runInAction(() => {
      this.user = null
      this.token = null
      localStorage.removeItem(USER_TOKEN_KEY)
      localStorage.removeItem(USER_ID_KEY)
    })
  }

  tryToLoadUser = async (): Promise<void> => {
    const userId = localStorage.getItem(USER_ID_KEY)
    const token = localStorage.getItem(USER_TOKEN_KEY)

    if (userId !== null && token !== null) {
      const user = await this.root.tryToFetch<User>(getUser(Number(userId), token))
      runInAction(() => {
        this.user = user
        this.token = token
      })
    }
  }

  updateUser = async (user: Partial<User> & { id: number }): Promise<void> => {
    const updatedUser = await this.root.tryToFetch<User>(updateUser(user, this.token ?? ''))

    if (updatedUser !== null) {
      runInAction(() => {
        this.user = updatedUser
      })

      notification.success({
        message: 'Updates saved successfully'
      })
    }
  }
}

export function createUserStore (root: AppStore): UserStore {
  return new UserStoreImpl(root)
}
