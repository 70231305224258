import React, { type FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { DashboardOutlined, AppstoreAddOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { PageLayout, StoreForm } from '../components'
import { useAppStore } from '../store'

export const StorePage: FC = observer(() => {
  const { storeId } = useParams()
  const { breadcrumb } = useAppStore()

  useEffect(() => {
    breadcrumb.setBreadcrumbItems([
      {
        title: 'Dashboard',
        link: '/',
        icon: <DashboardOutlined />
      },
      {
        title: 'Stores',
        link: '/stores',
        icon: <AppstoreAddOutlined />
      },
      {
        title: storeId !== undefined ? 'Edit store' : 'Add new store'
      }
    ])
  }, [breadcrumb])

  return (
    <PageLayout>
      <StoreForm storeId={storeId} />
    </PageLayout>
  )
})
